.cancel--btn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding-bottom: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
 
.ogadsApi .header{
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -10px;

  background-color: #5f79ff;
}
.ogadsApi .sub-header{
  margin-left: -10px;
  margin-right: -10px;
  background-color: #3f5ae0;
}
 

.ogadsApi .header img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  vertical-align: middle;
}

.ogadsApi .section{ 
  display: block; 
}
.ogadsApi .app {
  cursor: pointer;
  margin: 15px 15px;
}

.ogadsApi .sub-header p {
  color: #fff;
  padding: 10px 0;
} 

.ogadsApi .app .text-content .title {
  color: #808dad !important;
  display: inline-block;
  margin-right: 5px; 
  margin-bottom: -5px;
}
.ogadsApi .sub-header {
  text-align: center;
  filter: brightness(90%);
}
.ogadsApi  .header {
  text-align: center;
  padding: 20px 0;
}
.ogadsApi .app .app-btn {
  border: 0;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  float: right;
  /*margin-top: 18px;
  */padding: 7px 10px;
  position: relative;
  text-transform: uppercase;
  vertical-align: middle;
 
}

.ogadsApi .app .img-content {
  display: inline-block;
  vertical-align: middle;
  
}
.ogadsApi .app .text-content {
  display: inline-block;
  padding-left: 10px;
  vertical-align: middle;
  width: 40%;
} 

.ogadsApi .app .text-content .desc {
  color: silver;
  word-wrap: break-word;      
  font-size: 12px;
}

.ogadsApi .app .app-btn  {
  background-color: #5f79ff;
}






.nameCircle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    -moz-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    -o-transform: translateY(-50%) translateX(-50%);
}
.offer_api_modal{
  width:50%!important;
  top: 100px; 
  background-color: #191e3c;
  overflow:  hidden ;
  z-index:  9999 ;
  padding:20px;
  position: relative;
  left: 25%;
}
.account--img{
  border: 1px solid #721c24;
  padding:3px;
  
}
.points--getway{
  color: #fff;
}
.modal-header{
  padding-bottom: 10px ;
  display: flex;
  justify-content: space-around;
}
.surveyModalCoins{ 
  font-weight: 600; 
  color: #fff;
  align-items: center;
  white-space: nowrap;
}
.surveyModalCoinsIcon{
  height: 25px;
  width: 20px;
  margin-right: 6px;
}
 .btn--startOffer{
  color: white;
  background-color: #358455;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.Modal_body{
  background-color:#2a2f52;
  padding: 10px;
  margin: 10px
}

.modal-header .h4 {
  position: relative;
  width: 50%;
  bottom: 5px;
  margin-left: 15px;
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;
  color: rgba(255,255,255,0.95);

 
}
.earnFeedCarousel{
  overflow: scroll;
}
.earnFeedCarousel::-webkit-scrollbar {
  /* width: 1px; */
  /* height: 5px; */
  display: none;

}
.div_danger{
  color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.filter-green{
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(330deg) brightness(118%) contrast(119%);
  -webkit-filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(330deg) brightness(118%) contrast(119%);
}
.modal-90w {  width: 100% !important; }
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.modal-lg{
  height: 40vmin !important;
}
.div_info{
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  padding-right: 35px;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

/* spinner */


@keyframes spinner {
  to {transform: rotate(360deg);}
}
 
.spinner:before {
  content: '';
  box-sizing: border-box;
  /* position: absolute; */
  /* top: 50%;
  left: 50%; */
  /* new */
  display: inline-flex;
  margin-right: 10px;

  /* end new */
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner .6s linear infinite;
  background-color: #f57059;
}

/* colors */
.a1{
  background-color:#d03545 ;
  color: #fff;
}

.a2{
  background-color: rgb(242, 239, 69);
  color: #000;
}
.a3{
  background-color: rgb(2, 159, 182);
  color: #fff;
}


.a4{
  background-color: rgb(112, 200, 166);
  color: #000;
}
.a5{
  background-color: rgb(76, 213, 46);
  color: #fff;
}
.a6{
  background-color: rgb(231, 29, 210);
  color: #fff;
}

.a7{
  background-color: rgb(27, 168, 94);
  color: #fff;
}
/* end colors */
/* end spinner */

.rewards--row {
  background-color: var(--color-tertiary);
  /* margin-top: 7.2rem; */
  border-radius: 0.8rem;
  box-sizing: border-box;
  padding: 2.4rem;
  /* height: 73.4rem; */
  margin: 10px;
}

@media (min-width: 900px) {
  .upbarPopup{
    width:50%;
    height:80%;
    left:25% !important;  
  }
.row_rewards{
  display: flex;
  padding: inherit;
    border-radius: inherit;
}
  .box_reward_half{
    background-color: #0b122f;
    padding: inherit;
    margin: 20px;
    width: 49%;
    border-radius: inherit;
  }
  .box_reward{
    background-color: #0b122f;
      padding: inherit;
      margin: 20px;
      width: 25%;
      border-radius: inherit;
  }
}
@media (min-width:460px) and (max-width:900px) {

  .offer_api_modal{
    width:70%!important;
    top: 100px; 
     
    left: 15%;
  }
  .modal-header{
    display: block;
    text-align: center;
  }

  .modal-header .h4 {
    position: relative;
    width: 100%;
    bottom: 5px;
    margin-left: 15px;
    font-size: 20px;
    font-weight: 700;
    line-height: 150%;
    color: rgba(255,255,255,0.95);
    padding-top: 10px;
    padding-bottom : 10px; 
  }
 
  .upbarPopup{
    width:70%;
    height:80%;
    left:15% !important;  
  }
.row_rewards{
  display: flex;
  padding: inherit;
    border-radius: inherit;
}
  .box_reward_half{
    background-color: #0b122f;
    padding: inherit;
    margin: 20px;
    width: 49%;
    border-radius: inherit;
  }
  .box_reward{
    background-color: #0b122f;
      padding: inherit;
      margin: 20px;
      width: 25%;
      border-radius: inherit;
  }
}
@media (max-width: 460px) {
  .earnFeedCarousel::-webkit-scrollbar {
    /* width: 5px; */
    display: none;
  
  }
  .offer_api_modal{
    width:90%!important;
    top: 100px; 
     
    left: 5%;
  }

  .modal-header{
    display: block;
    text-align: center;
  }

  .modal-header .h4 {
    position: relative;
    width: 100%;
    bottom: 5px;
    margin-left: 15px;
    font-size: 20px;
    font-weight: 700;
    line-height: 150%;
    color: rgba(255,255,255,0.95);
  padding-top: 10px;
  padding-bottom : 10px;
   
  }


  .upbarPopup{
    width:80%;
    height:80%;
    left:10%; 
  }
  .row_rewards{
    display: block;
    padding: inherit;
    border-radius: inherit;
  }
  .box_reward_half{
    background-color: #0b122f;
    padding: inherit;
    margin: 20px;
    width: 95%;
    border-radius: inherit;
  }
  .box_reward{
    background-color: #0b122f;
      padding: inherit;
      margin: 20px;
      width: 95%;
      border-radius: inherit;
  }
}

/* scroll */
/* width */
::-webkit-scrollbar {
  width: 5px;
  /* display: none; */

}

 
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #358455; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #358455; 
}


/* end scroll */
.disableInput{
  text-align: center;
  background-color: #0b122f;
  padding: 15px;
  margin: auto;
}
.disableInput span{
  color:red;
  font-size:large;
}
.avatarBar{
  shape-outside: circle(50%); 
  text-align: center;
  padding: 10px;
  border-radius: 50%;
}

.avatarChat{
  shape-outside: circle(50%); 
  text-align: center;
  padding: 10px;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
}

.span_tooltip{
  text-align: left !important;
  color: #65678d;
  border-radius: 3px;
  display: inline-block;
  width: 90px;
}

.tippy-content {
  text-align: left !important;
  /* background-color: var(--color-tertiary); */
  color: var(--color-primary);
  padding: 15px;
}
.tippy-box {
  text-align: left !important;
  position: relative;
  background-color:#191e3c;
 
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4; 
  transition-property: transform,visibility,opacity;
   
}
.tippy-tooltip [x-circle]{
  background-color :inherit !important;
}
/* btn */
 
.btn_do  {
    color: #FFF;
    background-color: #59f566;
    border: 1px solid #59f58d;
}
 
.btn_do:hover {
    background-color: #71cd71;
    border-color: #71cd71;
    cursor: pointer;
}
 
.reward--table,.reward--table th,.reward--table td {
  border: 1px solid black;
  border-collapse: collapse;
}
/*  page confirm code */
.container-2{
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 30rem;
} 
.card-2{
  margin-bottom: 0.75rem;
  box-shadow: rgb(53 64 82 / 4%) 0 2px 4px 0;
  position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #808dad;
    background-clip: border-box;
    border: 1px solid rgba(110,117,130,.2);
    border-radius: 3px;
}
.text-2{
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 3px;
}
.offer--wall--item{
  cursor: pointer;
}
iframe {
  border:initial

}
.titleApiCard{
   
    font-size: 14px;
    font-weight: 800;
    flex: 1;
}
.featured-survey-amount{
  color: black;
  font-weight: 600;
  padding: 4px;
  inset: -4px -6px;
  background-color: #01d676;
  border-radius: 5px;
  font-size: small;
}
.text-ellipsis{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* avatar register */
.div_avatar{
  text-align: center;
  padding-bottom: 20px;
} 
 
.avatarRegister{
  padding:10px;
  width:100px;
  height: 100px;
  border-radius: 50%;
  border:1px solid #721c24;
  text-align: center;
}

/*  end avatar */
.alert-danger{
  color: #290606;
    background-color: #f5d2d2;
    border-color: #eba6a5;
    
}
.margin_load{
  margin: auto;
}
.alert-success{
  color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    
}
/* end page confirm code */
 .btn_do  {
     height: 35px;
     margin: 0;
     padding: 6px 12px;
     border-radius: 2px;
     font-family: inherit;
     font-size: 100%;
     color: inherit;
 }
 /* //////////// btn */
.div_info a{
color: #5D5F61 !important;
  text-decoration: none !important;
  background-color: transparent;
}

/* loading */

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to { 
      transform: rotate(360deg);
  }
}


@-webkit-keyframes rotate {
  from {
      -webkit-transform: rotate(0deg);
  }
  to { 
      -webkit-transform: rotate(360deg);
  }
}
nav ul .active {
  color: var(--color-primary) !important;
}
nav ul .active img {
  filter: invert(57%) sepia(8%) saturate(3996%) hue-rotate(320deg) brightness(98%) contrast(97%);
  -webkit-filter: invert(57%) sepia(8%) saturate(3996%) hue-rotate(320deg) brightness(98%) contrast(97%);
}
.notActive, .notActive img{
  color: var(--color-secondary) !important;
  filter: none  !important;
  -webkit-filter: none  !important;
}
 
.load {
width: 100px;
height: 100px;
margin: 110px auto 0;
border:solid 10px #5ef559;
border-radius: 50%;
border-right-color: transparent;
border-bottom-color: transparent;
 -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name:             rotate; 
  -webkit-animation-duration:         1.0s; 
  -webkit-animation-iteration-count:  infinite;
  -webkit-animation-timing-function: linear;
    
     transition: all 0.5s ease-in;
  animation-name:             rotate; 
  animation-duration:         1.0s; 
  animation-iteration-count:  infinite;
  animation-timing-function: linear; 
}
/* end loading */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.activeCard{
  border: 1px solid;
}
.App-link {
  color: rgb(112, 76, 182);
}
.ReactModal__Overlay {
  background-color:#4a4646b3 !important;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
